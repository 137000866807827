/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    code: "code",
    pre: "pre"
  }, props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      children: "Line Heights"
    }), "\n", _jsxs(_components.p, {
      children: ["If you'd like to customize your values for line heights, use the ", _jsx(_components.code, {
        children: "theme.lineHeights"
      }), " section of your theme."]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-diffjs",
        children: "  // theme.js\n  export const theme = {\n    lineHeights: {\n      none: 1,\n      tight: 1.25,\n      snug: 1.375,\n      normal: 1.5,\n      relaxed: 1.625,\n-     loose: 2,\n+     'extra-loose': 3,\n      3: '.75rem',\n      4: '1rem',\n      5: '1.25rem',\n      6: '1.5rem',\n      7: '1.75rem',\n      8: '2rem',\n      9: '2.25rem',\n      10: '2.5rem',\n+     12: '3rem',\n    },\n  }\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["If you don't want to customize it, a set of ", _jsx(_components.code, {
        children: "lineHeights"
      }), " is already defined in default theme:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "const defaultTheme = {\n  // ...\n  lineHeights: {\n    none: 1,\n    tight: 1.25,\n    snug: 1.375,\n    normal: 1.5,\n    relaxed: 1.625,\n    loose: 2,\n    3: '.75rem',\n    4: '1rem',\n    5: '1.25rem',\n    6: '1.5rem',\n    7: '1.75rem',\n    8: '2rem',\n    9: '2.25rem',\n    10: '2.5rem',\n\n    // Match fontSizes\n    xs: '1rem',\n    sm: '1.25rem',\n    default: '1.5rem',\n    lg: '1.75rem',\n    xl: '1.75rem',\n    '2xl': '2rem',\n    '3xl': '2.25rem',\n    '4xl': '2.5rem',\n    '5xl': 1,\n    '6xl': 1,\n    '7xl': 1,\n    '8xl': 1,\n    '9xl': 1,\n  },\n}\n"
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = props.components || ({});
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
