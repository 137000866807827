/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import CustomizeLineHeights from '../../../partials/customizing/line-heights.mdx';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "line-height",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#line-height",
    "aria-label": "line height permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Line Height"), "\n", React.createElement(_components.p, null, "Utilities for controlling the leading (line height) of an element."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "lineHeight={size}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "line-height: {size};"))))), "\n", React.createElement(_components.h2, {
    id: "relative-line-heights",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#relative-line-heights",
    "aria-label": "relative line heights permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Relative line heights"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "\"none\""), ", ", React.createElement(_components.code, null, "\"tight\""), ", ", React.createElement(_components.code, null, "\"snug\""), ", ", React.createElement(_components.code, null, "\"normal\""), ", ", React.createElement(_components.code, null, "\"relaxed\""), ", and ", React.createElement(_components.code, null, "\"loose\""), " utilities to give an element a relative ", React.createElement(_components.code, null, "line-height"), " based on its current ", React.createElement(_components.code, null, "font-size"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div spaceY={8}>\n      {['none', 'tight', 'snug', 'normal', 'relaxed', 'loose'].map(\n        (lineHeight) => (\n          <x.dl key={lineHeight} color=\"light-blue-700\" overflow=\"hidden\">\n            <x.dt\n              w={16}\n              flexShrink={0}\n              fontSize=\"sm\"\n              opacity={0.8}\n              fontFamily=\"mono\"\n              mb={1}\n            >\n              lineHeight={lineHeight}\n            </x.dt>\n            <x.dd fontSize=\"xl\" lineHeight={lineHeight} m={0}>\n              Lorem ipsum dolor sit amet, consectetur adipisicing elit.\n              Assumenda, quia temporibus eveniet a libero incidunt suscipit\n              laborum, rerum accusantium modi quidem, ipsam illum quis sed\n              voluptatum quae eum fugit earum.\n            </x.dd>\n          </x.dl>\n        ),\n      )}\n    </x.div>\n  </template>\n  <x.p lineHeight=\"none\">Lorem ipsum ...</x.p>\n  <x.p lineHeight=\"tight\">Lorem ipsum ...</x.p>\n  <x.p lineHeight=\"snug\">Lorem ipsum ...</x.p>\n  <x.p lineHeight=\"normal\">Lorem ipsum ...</x.p>\n  <x.p lineHeight=\"relaxed\">Lorem ipsum ...</x.p>\n  <x.p lineHeight=\"loose\">Lorem ipsum ...</x.p>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "fixed-line-heights",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#fixed-line-heights",
    "aria-label": "fixed line heights permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Fixed line-heights"), "\n", React.createElement(_components.p, null, "Use the ", React.createElement(_components.code, null, "lineHeight={size}"), " utilities to give an element a fixed line-height, irrespective of the current font-size. These are useful when you need very precise control over an element's final size."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div spaceY={8}>\n      {[3, 4, 5, 6, 7, 8, 9, 10, '3rem', '20px'].map((lineHeight) => (\n        <x.dl key={lineHeight} color=\"emerald-700\" overflow=\"hidden\">\n          <x.dt\n            w={16}\n            flexShrink={0}\n            fontSize=\"sm\"\n            opacity={0.8}\n            fontFamily=\"mono\"\n            mb={1}\n          >\n            lineHeight={lineHeight}\n          </x.dt>\n          <x.dd fontSize=\"xl\" lineHeight={lineHeight} m={0}>\n            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda,\n            quia temporibus eveniet a libero incidunt suscipit laborum, rerum\n            accusantium modi quidem, ipsam illum quis sed voluptatum quae eum\n            fugit earum.\n          </x.dd>\n        </x.dl>\n      ))}\n    </x.div>\n  </template>\n  <x.p lineHeight={3}>Lorem ipsum ...</x.p>\n  <x.p lineHeight={4}>Lorem ipsum ...</x.p>\n  <x.p lineHeight={5}>Lorem ipsum ...</x.p>\n  <x.p lineHeight={6}>Lorem ipsum ...</x.p>\n  <x.p lineHeight={7}>Lorem ipsum ...</x.p>\n  <x.p lineHeight={8}>Lorem ipsum ...</x.p>\n  <x.p lineHeight={9}>Lorem ipsum ...</x.p>\n  <x.p lineHeight={10}>Lorem ipsum ...</x.p>\n  <x.p lineHeight=\"3rem\">Lorem ipsum ...</x.p>\n  <x.p lineHeight=\"20px\">Lorem ipsum ...</x.p>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the space between elements at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "lineHeight={{ md: \"relaxed\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "lineHeight=\"relaxed\""), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div lineHeight={{ md: 'relaxed' }}>{/* ... */}</x.div>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."), "\n", React.createElement(_components.h2, {
    id: "customizing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customizing",
    "aria-label": "customizing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customizing"), "\n", "\n", React.createElement(CustomizeLineHeights), "\n", React.createElement(_components.h2, {
    id: "styled-bindings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#styled-bindings",
    "aria-label": "styled bindings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Styled bindings"), "\n", React.createElement(_components.h3, {
    id: "automatic",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#automatic",
    "aria-label": "automatic permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Automatic"), "\n", React.createElement(_components.p, null, "Using xstyled's ", React.createElement(_components.code, null, "styled"), ", all line heights defined are automatically bound to ", React.createElement(_components.code, null, "line-height"), " attribute:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled from '@xstyled/...'\n\nconst Title = styled.h4`\n  line-height: loose;\n`\n")), "\n", React.createElement(_components.p, null, "To learn more about styled syntax, read ", React.createElement(_components.a, {
    href: "/docs/magic-styled-components/"
  }, "styled syntax documentation"), "."), "\n", React.createElement(_components.h3, {
    id: "manual",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#manual",
    "aria-label": "manual permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Manual"), "\n", React.createElement(_components.p, null, "It is possible to manually bind a line height using ", React.createElement(_components.code, null, "th.lineHeight"), " utility:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import styled from '...'\nimport { th } from '@xstyled/...'\n\nconst Title = styled.h4`\n  font: arial 10px / ${th.lineHeight('loose')};\n`\n")), "\n", React.createElement(_components.h2, {
    id: "hooks",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hooks",
    "aria-label": "hooks permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hooks"), "\n", React.createElement(_components.p, null, "Get a font size in any component using ", React.createElement(_components.code, null, "useLineHeight"), " hook:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import { useLineHeight } from '@xstyled/...'\n\nfunction Title() {\n  const lineHeight = useLineHeight('loose')\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
